/*
 * 업무구분: 나의활동골든룰(골든룰)
 * 화 면 명: MSPAP308M
 * 화면설명: 골든룰
 * 작 성 일: 2024.04
 * 작 성 자: 이정빈
 */
<template>
  <ur-page-container class="msp" :show-title="false" title="활동골든룰" :topButton="true" ref="page"> <!-- 2024.03.15 타이틀 텍스트 변경: 나의 활동골든룰 -> 활동골든룰 -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="26" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_ClickBtnPrevious">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '활동골든룰' : '활동골든룰'}}</div> <!-- 2024.03.15 타이틀 텍스트 변경: 나의 활동골든룰 -> 활동골든룰 -->
              <!-- 2024.03.15 활동현황상세 버튼 추가 start-->
              <div slot="action">
                <mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="fn_moveToMSPAP309P">활동상세내역</mo-button> 
              </div>
              <!-- 2024.03.15 활동현황상세 버튼 추가 end-->
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container v-if="!lv_blankPage && lv_monitorFlag && !lv_prgStatCd_3" direction="column" alignV="start" class="con-area pb80 mgr-gradient">
              <ur-box-container direction="column" alignV="start" class="golden-weeks mt30">
                <div class="gw-head">
                  <mo-button class="btn-arrow-sq prev" v-if="lv_preMonthValue" @click="fn_getCalcMonth(false)"><mo-icon class="crTy-bk1" icon="msp-arrow-pre"/></mo-button>
                  <!-- <span class="gw-tit"><em>{{lv_actInptYm}}</em>월 골든룰</span> -->
                  <span class="gw-tit"><em>{{lv_actInptYm}}</em>월 {{lv_gldnrNm}}</span>
                  <mo-button class="btn-arrow-sq next" v-if="lv_curMonthValue" @click="fn_getCalcMonth(true)"><mo-icon class="crTy-bk1" icon="msp-arrow-pre"/></mo-button>
                </div>
                <div class="gw-head-cont mt10">
                  <span v-if="!lv_acvmtYnValue" class="gw-sub-txt fs17rem">
                    {{lv_wklyPlnStndw}}주차 활동미션 달성하면<br> 
                    <em class="emp">{{lv_onwCmpsCntnt}}</em>
                  </span>
                  <mo-button shape="solid" class="btn-underline"  @click="open">주차별상품</mo-button>
                </div>
                <ul class="gw-progress-wrap pb16">
                  <li :class="list.achievedClass" @click="fn_changeWkClick(index)" v-for="(list, index) in lv_GldnrActWekly" :key="index">
                    <span class="gw-icon">{{list.achievedNm}}</span> 
                    <span class="gw-txt">{{list.weklyDgr}}주차</span>
                  </li>
                </ul>
              </ur-box-container>
              <ur-box-container direction="column" alignV="start" class="gw-cont-detail">
                <div class="gw-chart-head">
                  <span class="fs18rem fwb">{{lv_clickedWk}}주차 활동내역</span>
                  <span class="fs14rem crTy-bk7">{{lv_mnStrYmd+' ~ '+lv_mnEndYmd}}</span>
                </div>
                <ur-box-container v-if="iCGldnrActWeklySmupDtlVO != null && !lv_noPrcWeek"   alignV="start" componentid="" direction="column" class="gwc-cont-wrap">
                  <mo-list :list-data="iCGldnrActWeklySmupDtlVO">
                    <template #list-item="{item}">
                      <mo-list-item>
                        <div class="list-item__contents">
                          <div class="gwc-graph">
                            <span class="txt-arrow" @click="fn_moveToMSPAP309P">{{item.gldnrActTypNm}}</span>
                            <div class="bar-chart" :class="item.gldnrActTypAcvmtYn === 'Y' ? 'complete' : ''">
                              <div class="fill-bar" :style="item.actCnt <= item.gldnrActTypGoalVal01 ? {width: `${(item.actCnt / item.gldnrActTypGoalVal01) * 100}%`} : {width: `${100}%`}"></div>
                            </div>
                          </div>
                          <div class="gwc-score">
                            <mo-badge v-if="item.gldnrActTypAcvmtYn === 'Y'" class="badge-sample-badge primary sm" text="달성" shape="status">달성</mo-badge> 
                            <mo-badge v-else class="badge-sample-badge gray sm" text="미달성" shape="status">미달성</mo-badge>
                            <span class="score-txt" :class="item.gldnrActTypAcvmtYn === 'Y' ? 'complete' : ''"><em>{{item.actCnt}}</em> / {{item.gldnrActTypGoalVal01}}</span>
                          </div>
                        </div>
                      </mo-list-item>
                    </template>
                  </mo-list>
                </ur-box-container>
                <ur-box-container v-else-if="iCGldnrActWeklySmupDtlVO != null && lv_noPrcWeek"  alignV="start" componentid="" direction="column" class="no-data wrap-txt">
                  <div class="w100">
                      <p>활동프로모션을<br>운영하지 않습니다.</p>
                  </div>
                </ur-box-container>
              </ur-box-container>
            </ur-box-container>

            <!-- 2024.03.15  no-data: 데이터없을시 -->
            <ur-box-container v-if="!lv_blankPage && !lv_curMonthChk" alignV="start" direction="column" class="no-data">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info"> 
                    <span class="ns-ftcr-gray">
                      <p class="mt10">
                        진행중인 활동골든룰이 없습니다.
                      </p>
                      <mo-button v-if="lv_preMonthChk" @click="fn_showPreMont()" color="primary" shape="border" size="small" class="ns-btn-round black">전월 골든룰보기</mo-button>
                    </span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <ur-box-container v-else-if="!lv_blankPage && lv_curMonthChk && lv_prgStatCd_2" alignV="start" direction="column" class="no-data">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info"> 
                    <span class="ns-ftcr-gray">
                      <p class="mt10">
                        진행중인 활동골든룰이 없습니다.
                      </p>
                      <mo-button v-if="lv_preMonthChk" @click="fn_showPreMont()" color="primary" shape="border" size="small" class="ns-btn-round black">전월 골든룰보기</mo-button>
                    </span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!--// 2024.03.15  no-data: 데이터없을시 -->
            <!--진행상태 일시중지일 때-->
            <ur-box-container v-else-if="!lv_blankPage && lv_curMonthChk && lv_prgStatCd_3" alignV="start" direction="column" class="no-data">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info"> 
                    <span class="ns-ftcr-gray">
                      <p class="mt10">
                        진행중인 활동골든룰이 일시중지되었습니다.
                      </p>
                      <mo-button v-if="lv_preMonthChk" @click="fn_showPreMont()" color="primary" shape="border" size="small" class="ns-btn-round black">전월 골든룰보기</mo-button>
                    </span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
          </template>

        </mo-collapsing-header-view>

      </div>
    </ur-box-container>

    <mo-bottom-sheet v-if="lv_GldnrActWekly !== null" ref="bottomSheet" :close-btn="false"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        주차별 상품
        <div class="ns-btn-close" @click="close" name="닫기"></div>
      </template>
      <div class="content-area mhAuto pb29">
        <ul class="round-box-list">
          <li class="round-box bgcolor-blue" v-show="list.gldnrOnwCmpsCntnt !== ''" v-for="(list, index) in lv_GldnrActWekly" :key="index">
            <span class="fs16em">{{list.weklyDgr}}주 달성</span>
            <div class="wp-cont-box"><span>{{list.gldnrOnwCmpsCntnt}}</span></div>
          </li>
        </ul>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" @click="close" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP308M",
    screenId: "MSPAP308M",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    async created() {
      console.log('@@@@@ MSPAP308M route params >>>> ', this.$route.params)
      this.lv_prePageId = this.$route.params.prePageId
      this.lv_flagVal = this.$route.params.flagVal
      if(this.lv_flagVal === 'N'){
        this.lv_calcMon = await this.fn_getDate(-1)
      }else if(this.lv_flagVal === 'Y' || this.lv_flagVal === undefined){
        this.lv_calcMon = await this.fn_getDate(0)
      }

      await this.fn_preLoadData()
      await this.fn_curLoadData()
      await this.fn_gldnrActrExist()

      this.lv_blankPage = false
      
    },
    mounted () {
     // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_gldnrActrExist:false,
        lv_prePageId:'',
        lv_flagVal:'',
        lv_calcMon: '',//전월 / 당월
        lv_Params:{
          cnsltNo:'',  //컨설턴트NO
          stndYm: ''   //기준년월
        },
        lv_actInptYm: '',         //활동월
        lv_gldnrNm: '',           //골든룰명[2024-09-10] 추가
        lv_Gldnr: [],
        tmpPreICGldnrActrBasInfoVO:[],
        tmpCurICGldnrActrBasInfoVO:[],
        lv_wklyPlnStndw: '',      //기준주차
        lv_clickedWk:'',          //선택한주차
        lv_index:'',
        lv_onwCmpsCntnt: '',      //주보상내용
        lv_mnStrYmd: '',          //주차시작일
        lv_mnEndYmd: '',          //주차종료일
        //달성,미달성,진행중,예정
        lv_acvmtYnData: [
          {key: '1', label: '달성'},
          {key: '2', label: '미달성'},
          {key: '3', label: '진행중'},
          {key: '4', label: '예정'},
          {key: '5', label: '미진행'}
        ],
        //달성,미달성,진행중,예정 class
        lv_acvmtYnClassData: [
          {key: '1', label: 'gw-state achieved'},
          {key: '2', label: 'gw-state unachieved'},
          {key: '3', label: 'gw-state in-progress'},
          {key: '4', label: 'gw-state scheduled'},
          {key: '5', label: 'gw-state unachieved2'}
        ],
        iCGldnrActWeklySmupDtlVO:[],
        tmpPreICGldnrActWeklySmupVO:[],
        tmpCurICGldnrActWeklySmupVO:[],
        lv_GldnrActWekly: [
          {
            stndYm:'',//기준년월
            cnsltNo:'',//컨설턴트번호
            prgStatCd:'',//골든룰진행상태코드
            iCGldnrActWeklySmupVO:[
              {
                weklyDgr:'',//주별차수
                curYn:'', //현재주 여부
                acvmtYn:'',//달성:1/미달성:2/진행중:3/예정:4 여부
                gldnrMnStrYmd:'',//골든룰주시작일자
                gldnrMnEndYmd:'',//골든룰주종료일자
                gldnrOnwCmpsCntnt:'',//골든룰주보상내용
                iCGldnrActWeklySmupDtlVO : [
                  {
                    gldnrActTypCd:'',//골든룰활동유형코드
                    actCnt:'',//활동건수
                    gldnrActTypGoalVal01:'',//골든룰활동유형목표값
                    gldnrActTypAcvmtYn:''//골든룰활동유형별 달성/미달성 여부
                  }
                ]
              }
            ]
          }
        ],
        lv_AcvmtItmData: [
          { key: '01', label: '조회' },
          { key: '02', label: '귀점' },
          { key: '03', label: '귀점후활동' },
          { key: '04', label: '필컨동의' },
          { key: '05', label: '신규필컨' },
          { key: '06', label: '통합보장설계' },
          { key: '07', label: '가입설계' },
          { key: '08', label: '청약(발행)' },
          { key: '09', label: '신계약' },
          { key: '10', label: '컨설팅' },
          { key: '11', label: '전화' },
          { key: '12', label: '문자' },
          { key: '13', label: '이메일' },
          { key: '14', label: '조회&귀점' },
          // { key: '15', label: '사업자등록증' }
        ],
        lv_HeaderviewScrollCompID:'',
        lv_preMonthChk: false,
        lv_curMonthChk: false,
        lv_monitorFlag: false,
        lv_prgStatCd: false,
        lv_prgStatCd_2: false,
        lv_prgStatCd_3: false,
        lv_preMonthValue:true,
        lv_curMonthValue:true,
        lv_blankPage:true, //처음 로딩 시 화면초기화 
        lv_curYn: 0,
        lv_acvmtYnChk: '',
        lv_noPrcWeek: false,
        lv_acvmtYnValue: false,
        lv_paramPreMonthChk: false,
        lv_paramCurMonthChk: false,
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*******************************************************************************
      * Function명: fn_showPreMont
      * 설명: 전월보기 
      *******************************************************************************/
      fn_showPreMont(){
        this.lv_prgStatCd_3 = false
        this.fn_getCalcMonth(false)
      },
      /*******************************************************************************
      * Function명: fn_preLoadData
      * 설명: 전월 골든룰 데이터 유무 확인
      *******************************************************************************/
      async fn_preLoadData(){
        this.lv_Params = {
          cnsltNo : this.getStore('userInfo').getters.getUserInfo.userEno, 
          stndYm : this.fn_getDate(-1)
        }
        
        let pParams = this.lv_Params
        var lv_Vm = this
        const trnstId = 'txTSSAP09S3'
        const auth = 'S'

        await this.post(lv_Vm, pParams, trnstId, auth).then(response => {
          if (response.body != null && response.body.iCGldnrActWeklySmupVO !== null){
            if (response.body.iCGldnrActWeklySmupVO.length > 0) {
              // console.log(" @@@@@@  response.body this.fn_getDate(-1) >> "+response.body.iCGldnrActWeklySmupVO)
              lv_Vm.tmpPreICGldnrActWeklySmupVO = response.body.iCGldnrActWeklySmupVO
              lv_Vm.tmpPreICGldnrActrBasInfoVO = response.body.iCGldnrActrBasInfoVO

              if(response.body.dtlYn === 'Y'){
                lv_Vm.lv_paramPreMonthChk = true
              }else{
                lv_Vm.lv_paramPreMonthChk = false
              }

              lv_Vm.lv_preMonthChk = true
            
              lv_Vm.lv_monitorFlag = lv_Vm.lv_preMonthChk
              lv_Vm.fn_getCalcMonth(false)
            }else{
              lv_Vm.lv_preMonthChk = false
            }
          }else{
            lv_Vm.lv_preMonthChk = false
          }
        }).catch(function (error) {
          window.vue.error(error)
          lv_Vm.getStore('confirm').dispatch('ADD', '조회 실패 했습니다.')
        })
      },
      /******************************************************************************
       * Function명 : fn_curLoadData
       * 설명       : 골든룰 데이터 가져오기
      ******************************************************************************/
      async fn_curLoadData(){
        
        this.lv_Params = {
          cnsltNo : this.getStore('userInfo').getters.getUserInfo.userEno, 
          stndYm : this.fn_getDate(0)
        }
        
        let pParams = this.lv_Params
        var lv_Vm = this
        const trnstId = 'txTSSAP09S3'
        const auth = 'S'

        await this.post(lv_Vm, pParams, trnstId, auth).then(response => {
          if (response.body !== null && response.body.iCGldnrActWeklySmupVO !== null){
            if (response.body.iCGldnrActWeklySmupVO.length > 0) {
              if(response.body.prgStatCd === '2' || response.body.iCGldnrActrBasInfoVO[0].gldnrPrgStatCd === '2'){//진행대기
                lv_Vm.lv_prgStatCd = true
                lv_Vm.lv_prgStatCd_2 = true
              }
              if(response.body.prgStatCd === '3' || response.body.iCGldnrActrBasInfoVO[0].gldnrPrgStatCd === '3'){//일시중지
                lv_Vm.lv_prgStatCd = true
                lv_Vm.lv_prgStatCd_3 = true
              }

              // console.log(" @@@@@@  response.body this.fn_getDate(0) >> "+response.body.iCGldnrActWeklySmupVO)
              lv_Vm.tmpCurICGldnrActWeklySmupVO = response.body.iCGldnrActWeklySmupVO
              lv_Vm.tmpCurICGldnrActrBasInfoVO = response.body.iCGldnrActrBasInfoVO

              if(response.body.dtlYn === 'Y'){
                lv_Vm.lv_paramCurMonthChk = true
              }else{
                lv_Vm.lv_paramCurMonthChk = false
              }             

              lv_Vm.lv_curMonthChk = true
              
              lv_Vm.lv_monitorFlag = lv_Vm.lv_curMonthChk
              if(this.lv_flagVal !== 'N'){
                lv_Vm.fn_getCalcMonth(true)
              }
            }else{
              lv_Vm.lv_curMonthChk = false
            }
          }else{
            lv_Vm.lv_curMonthChk = false
          }
        }).catch(function (error) {
          window.vue.error(error)
          lv_Vm.getStore('confirm').dispatch('ADD', '조회 실패 했습니다.')
        })
      },
      /******************************************************************************
       * Function명 : fn_getCalcMonth
       * 설명       : 전월 당월 구하기
      ******************************************************************************/
      fn_getCalcMonth(flag){
        
        if(flag){
          if(this.lv_prgStatCd){
            this.lv_prgStatCd_3 = true
          }

          this.lv_curMonthValue = false
          if(!this.lv_preMonthChk){
            this.lv_preMonthValue = false
          }else{
            this.lv_preMonthValue = true
          }

          this.lv_GldnrActWekly = this.tmpCurICGldnrActWeklySmupVO
          this.lv_Gldnr = this.tmpCurICGldnrActrBasInfoVO
          this.lv_monitorFlag = this.lv_curMonthChk
          this.lv_calcMon = this.fn_getDate(0)
        }else{
          this.lv_curMonthValue = true
          if(!this.lv_preMonthChk){
            this.lv_preMonthValue = true
          }else{
            this.lv_preMonthValue = false
          }

          this.lv_GldnrActWekly = this.tmpPreICGldnrActWeklySmupVO
          this.lv_Gldnr = this.tmpPreICGldnrActrBasInfoVO
          this.lv_monitorFlag = this.lv_preMonthChk
          this.lv_calcMon = this.fn_getDate(-1)
        }
        
        this.fn_loadDtlData(flag)
        console.log(" @@@@@@@@@@@@@ dt >>>>>>>>>>>  "+this.lv_calcMon)
      },      
      /******************************************************************************
       * Function명 : fn_loadDtlData
       * 설명       : 데이터 매핑
      ******************************************************************************/
      fn_loadDtlData(flag){
        for(let i=0;i < this.lv_GldnrActWekly.length; i++){
          this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO = this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO.filter(item => item.gldnrActTypGoalVal01 !== 0)

          ////타입으로 정렬- by PSJ
          // this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO.sort(function(a,b){
          //   return a.gldnrActTypCd - b.gldnrActTypCd
          // })
        }


        if(this.lv_GldnrActWekly !== null){
          if(flag){
            // this.lv_curYn = 0 
            // let acvmtYn_4 = []
            // for(let i=0;i < this.lv_GldnrActWekly.length; i++){
            //   if(this.lv_GldnrActWekly[i].curYn === 'N'){
            //     if(this.lv_GldnrActWekly[i].acvmtYn === '1' || this.lv_GldnrActWekly[i].acvmtYn === '2'){
            //       this.lv_acvmtYnChk = i
            //     }else if(this.lv_GldnrActWekly[i].acvmtYn === '4'){
            //       acvmtYn_4.push(i)
            //     }
            //     this.lv_curYn++
            //   }
            // }
            // if(acvmtYn_4 !== null || acvmtYn_4 !== ''){
            //   this.lv_acvmtYnChk = acvmtYn_4[0]
            // }
            this.fn_changeWk()
          }else{
            this.fn_changeWkClick(0)
          }
        }
      },
      /******************************************************************************
       * Function명 : fn_changeWk
       * 설명       : 주별 데이터 구하기
      ******************************************************************************/
      fn_changeWk(){
        let _this = this
        for(let i=0;i < this.lv_GldnrActWekly.length; i++){
          // if(this.lv_curYn === 4){
          //   if(this.lv_acvmtYnChk === i){
          //     this.lv_actInptYm =  moment(this.lv_GldnrActWekly[i].gldnrMnStrYmd).format('MM')
          //     this.lv_wklyPlnStndw = this.lv_GldnrActWekly[i].weklyDgr
          //     this.lv_onwCmpsCntnt = this.lv_GldnrActWekly[i].gldnrOnwCmpsCntnt
          //     this.lv_clickedWk = this.lv_GldnrActWekly[i].weklyDgr
          //     this.lv_index = i
          //     this.lv_mnStrYmd = moment(this.lv_GldnrActWekly[i].gldnrMnStrYmd).format('YYYY-MM-DD')
          //     this.lv_mnEndYmd = moment(this.lv_GldnrActWekly[i].gldnrMnEndYmd).format('YYYY-MM-DD')

          //     this.lv_GldnrActWekly[i].achievedClass = this.lv_acvmtYnClassData.find(x => x.key === this.lv_GldnrActWekly[i].acvmtYn).label +' '+'on'
          //     this.iCGldnrActWeklySmupDtlVO = this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO
              
          //     if(this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO != null){
          //       for(let j=0;j < this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO.length; j++){
          //         this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO[j].gldnrActTypNm = this.lv_AcvmtItmData.find(x => x.key === this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO[j].gldnrActTypCd).label
          //       }
          //     }
          //   }else{
          //     this.lv_GldnrActWekly[i].achievedClass = this.lv_acvmtYnClassData.find(x => x.key === this.lv_GldnrActWekly[i].acvmtYn).label
          //   }
          //   this.lv_GldnrActWekly[i].achievedNm = this.lv_acvmtYnData.find(x => x.key === this.lv_GldnrActWekly[i].acvmtYn).label

          // }else{
            if(this.lv_GldnrActWekly[i].curYn === 'Y'){
              this.lv_actInptYm =  moment(this.lv_GldnrActWekly[i].gldnrMnStrYmd).format('MM')
              this.lv_gldnrNm = this.lv_Gldnr[0].gldnrNm //골든룰명 [2024-09-10]
              this.lv_wklyPlnStndw = this.lv_GldnrActWekly[i].weklyDgr
              this.lv_onwCmpsCntnt = this.lv_GldnrActWekly[i].gldnrOnwCmpsCntnt
              this.lv_clickedWk = this.lv_GldnrActWekly[i].weklyDgr
              this.lv_index = i
              this.lv_mnStrYmd = moment(this.lv_GldnrActWekly[i].gldnrMnStrYmd).format('YYYY-MM-DD')
              this.lv_mnEndYmd = moment(this.lv_GldnrActWekly[i].gldnrMnEndYmd).format('YYYY-MM-DD')

              if(this.lv_GldnrActWekly[i].acvmtYn === '5'){
                if(this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO.length === 0){
                  this.lv_noPrcWeek = true
                }else{
                  this.lv_noPrcWeek = false
                }
                this.lv_acvmtYnValue = true
              }else{
                this.lv_noPrcWeek = false
                this.lv_acvmtYnValue = false
              }

              this.lv_GldnrActWekly[i].achievedClass = this.lv_acvmtYnClassData.find(x => x.key === this.lv_GldnrActWekly[i].acvmtYn).label +' '+'on'
              this.iCGldnrActWeklySmupDtlVO = this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO
            }else{
              this.lv_GldnrActWekly[i].achievedClass = this.lv_acvmtYnClassData.find(x => x.key === this.lv_GldnrActWekly[i].acvmtYn).label
            }

            this.lv_GldnrActWekly[i].achievedNm = this.lv_acvmtYnData.find(x => x.key === this.lv_GldnrActWekly[i].acvmtYn).label

            if(this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO != null){
              for(let j=0;j < this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO.length; j++){
                this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO[j].gldnrActTypNm = this.lv_AcvmtItmData.find(x => x.key === this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO[j].gldnrActTypCd).label
              }
            }
          // }
        }
      },
      /******************************************************************************
       * Function명 : fn_changeWkClick
       * 설명       : 클릭한 주별 데이터 구하기
      ******************************************************************************/
      fn_changeWkClick(index){
        this.lv_actInptYm =  moment(this.lv_GldnrActWekly[index].gldnrMnStrYmd).format('MM')
        this.lv_gldnrNm = this.lv_Gldnr[0].gldnrNm //골든룰명 [2024-09-10]
        this.lv_wklyPlnStndw = this.lv_GldnrActWekly[index].weklyDgr
        this.lv_onwCmpsCntnt = this.lv_GldnrActWekly[index].gldnrOnwCmpsCntnt
        this.lv_clickedWk = this.lv_GldnrActWekly[index].weklyDgr
        this.lv_index = index
        this.lv_mnStrYmd = moment(this.lv_GldnrActWekly[index].gldnrMnStrYmd).format('YYYY-MM-DD')
        this.lv_mnEndYmd = moment(this.lv_GldnrActWekly[index].gldnrMnEndYmd).format('YYYY-MM-DD')
        for(let i=0;i < this.lv_GldnrActWekly.length; i++){
          if(index === i){
            this.lv_GldnrActWekly[i].achievedClass = this.lv_acvmtYnClassData.find(x => x.key === this.lv_GldnrActWekly[i].acvmtYn).label +' '+'on'
            this.iCGldnrActWeklySmupDtlVO = this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO
            if(this.lv_GldnrActWekly[i].acvmtYn === '5'){
              if(this.iCGldnrActWeklySmupDtlVO.length === 0){
                this.lv_noPrcWeek = true
              }else{
                this.lv_noPrcWeek = false
              }
              this.lv_acvmtYnValue = true
            }else{
              this.lv_noPrcWeek = false
              this.lv_acvmtYnValue = false
            }
          }else{
            this.lv_GldnrActWekly[i].achievedClass = this.lv_acvmtYnClassData.find(x => x.key === this.lv_GldnrActWekly[i].acvmtYn).label
          }
          this.lv_GldnrActWekly[i].achievedNm = this.lv_acvmtYnData.find(x => x.key === this.lv_GldnrActWekly[i].acvmtYn).label
          
          if(this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO != null){
            for(let j=0;j < this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO.length; j++){
              this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO[j].gldnrActTypNm = this.lv_AcvmtItmData.find(x => x.key === this.lv_GldnrActWekly[i].iCGldnrActWeklySmupDtlVO[j].gldnrActTypCd).label
            }
          }
        }
      },
      /******************************************************************************
       * Function명 : fn_getDate
       * 설명       : 월 계산
      ******************************************************************************/
      fn_getDate(val){
        let d = new Date()
        if(val !== 0){
          let lastDay = (new Date(d.getFullYear(), d.getMonth(),0)).getDate()
          if(d.getDate() > lastDay){
            d.setDate(lastDay)
          }
          d.setMonth(d.getMonth() -1)
        }
        return d.getFullYear() + ('0'+(d.getMonth()+1)).slice(-2)
      },
      /******************************************************************************
       * Function명 : fn_ClickBtnPrevious
       * 설명       : 뒤로가기
      ******************************************************************************/
      fn_ClickBtnPrevious () {
        if(this.lv_prePageId === 'MSPBC003M'){
          this.$router.push({name: 'MSPBC003M'})
        }else if(this.lv_prePageId === 'MSPAP105M'){
          this.$router.push({name: 'MSPAP105M'})
        }else{
          this.$router.push({name: 'MSPBC002M'})
        }
      },
      /******************************************************************************
       * Function명 : fn_moveToMSPAP309P
       * 설명       : 활동현황 상세페이지로 이동
      ******************************************************************************/
      fn_moveToMSPAP309P(){
       console.log(" @@@@@@@@@@@  MSPAP309P >> ")
        this.$router.push({name: 'MSPAP309P',params: {currentWK : this.lv_clickedWk, currentMon : this.lv_calcMon, preMonthChk : this.lv_paramPreMonthChk, curMonthChk: this.lv_paramCurMonthChk, index: this.lv_index, prePageId:this.lv_prePageId, gldnrActrExist:this.lv_gldnrActrExist}})
      },
      open() { 
        this.$refs.bottomSheet.open()
      },
      close() { 
        this.$refs.bottomSheet.close() 
      },
      /*********************************************************
     * Function명: fn_gldnrActrExist
     * 설명: 골든룰활동 컨설턴트 활동룰 포함 현황 조회
     *********************************************************/
      fn_gldnrActrExist(){
        let onpstOrgKndCd = this.getStore('userInfo').getters.getUserInfo.onpstOrgKndCd
        if(onpstOrgKndCd === '11' || onpstOrgKndCd === '20' || onpstOrgKndCd === '14' || onpstOrgKndCd === '13' || onpstOrgKndCd === '15'){
          this.lv_gldnrActrExist = true
        }
      }
    }
  };
</script>
<style scoped>
</style>